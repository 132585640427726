import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriasService {

  constructor(public api: ApiService) { }

  listAll() {
    return this.api.doRequest('get', 'categorias/all');
  }
  list(filter) {
    return this.api.doRequest('get', 'categorias', filter);
  }
  get(id) {
    return this.api.doRequest('get', 'categorias/' + id);
  }
  delete(id) {
    return this.api.doRequest('delete', 'categorias/' + id);
  }
  update(obj) {
    return this.api.doRequest('put', 'categorias', obj);
  }
  create(obj) {
    return this.api.doRequest('post', 'categorias', obj);
  }
}
