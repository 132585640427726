import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class HorasService {

  constructor(public api: ApiService) { }

  listAll() {
    return this.api.doRequest('get', 'horas/all');
  }
  tipos() {
    return this.api.doRequest('get', 'horas/tipos');
  }
  list(filter) {
    return this.api.doRequest('get', 'horas', filter);
  }
  get(data) {
    return this.api.doRequest('get', 'horas/' + data);
  }
  delete(id) {
    return this.api.doRequest('delete', 'horas/' + id);
  }
  update(obj) {
    return this.api.doRequest('put', 'horas', obj);
  }
  create(obj) {
    return this.api.doRequest('post', 'horas', obj);
  }
}
