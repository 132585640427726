import { Injectable } from "@angular/core";
import { ApiService } from "src/app/services/api.service";

@Injectable({
  providedIn: "root"
})
export class ProdutosService {
  constructor(public api: ApiService) {}

  listByFornecedor(id) {
    return this.api.doRequest("get", "produtos/fornecedor/" + id);
  }
  listAll() {
    return this.api.doRequest("get", "produtos/all");
  }
  list(filter) {
    return this.api.doRequest("get", "produtos", filter);
  }
  get(id) {
    return this.api.doRequest("get", "produtos/" + id);
  }
  delete(id) {
    return this.api.doRequest("delete", "produtos/" + id);
  }
  update(obj) {
    return this.api.doRequest("put", "produtos", obj);
  }
  create(obj) {
    return this.api.doRequest("post", "produtos", obj);
  }
  imposto() {
    return this.api.doRequest("get", "produtos/impostos");
  }
}
