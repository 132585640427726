import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
    providedIn: 'root'
})
export class UfCidadeService {

    constructor(public api: ApiService) { }

    listCidadesByUf(codigo) {
        return this.api.doRequest('get', 'ufcidade/' + codigo);
    }
    listUfCidadesById(id) {
        return this.api.doRequest('get', 'ufcidade/' + id);
    }
    listEstados() {
        return this.api.doRequest('get', 'ufcidade/estados');
    }
}
