import { Injectable } from "@angular/core";
import { ApiService } from "src/app/services/api.service";

@Injectable({
  providedIn: "root"
})
export class CamposService {
  constructor(public api: ApiService) {}

  listSituacao() {
    return this.api.doRequest('get', 'campos-dinamicos/status');
  }
  listAll() {
    return this.api.doRequest('get', 'campos-dinamicos/all');
  }
  list(filter) {
    return this.api.doRequest('get', 'campos-dinamicos', filter);
  }
  get(id) {
    return this.api.doRequest('get', 'campos-dinamicos/' + id);
  }
  delete(id) {
    return this.api.doRequest('delete', 'campos-dinamicos/' + id);
  }
  update(obj) {
    return this.api.doRequest('put', 'campos-dinamicos', obj);
  }
  create(obj) {
    return this.api.doRequest('post', 'campos-dinamicos', obj);
  }
}
