import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class RelatorioService {

  constructor(public api: ApiService) { }

  list(filter) {;
    return this.api.doRequest('get', 'projetos/relatorios/horas', filter);
  }
}
