import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NovaVendaService {

  constructor(public api: ApiService, private http: HttpClient) { }

  getTipoPagamento() {
    return this.http.get("assets/json/pagamento.json");
  }
  listAll() {
    return this.api.doRequest('get', 'vendas/all');
  }
  list(filter) {
    return this.api.doRequest('get', 'vendas', filter);
  }
  get(id) {
    return this.api.doRequest('get', 'vendas/' + id);
  }
  delete(id) {
    return this.api.doRequest('delete', 'vendas/' + id);
  }
  update(obj) {
    return this.api.doRequest('put', 'vendas', obj);
  }
  create(obj) {
    return this.api.doRequest('post', 'vendas', obj);
  }

  imprimir(obj){
    return this.api.doRequest('post', 'print', obj, 'http://localhost:3333/')
  }
}
