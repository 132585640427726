import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class PerfilAcessoService {

  constructor(public api: ApiService) { }

  getModules() {
    return this.api.doRequest('get', 'modulo/all');
  }
  listAll() {
    return this.api.doRequest('get', 'perfil-acesso/all');
  }
  list(filter) {
    return this.api.doRequest('get', 'perfil-acesso', filter);
  }
  get(id) {
    return this.api.doRequest('get', 'perfil-acesso/' + id);
  }
  delete(id) {
    return this.api.doRequest('delete', 'perfil-acesso/' + id);
  }
  update(obj) {
    return this.api.doRequest('put', 'perfil-acesso', obj);
  }
  create(obj) {
    return this.api.doRequest('post', 'perfil-acesso', obj);
  }

  listModule() {
    return this.api.doRequest('get', 'perfil-acesso/modulo');
  }
}
