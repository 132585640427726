import { Injectable } from "@angular/core";
import { ApiService } from "src/app/services/api.service";

@Injectable({
  providedIn: "root"
})
export class CategoriasService {
  constructor(public api: ApiService) {}

  listSituacao() {
    return this.api.doRequest('get', 'categoria-catalogo/status');
  }
  listAll() {
    return this.api.doRequest('get', 'categoria-catalogo/all');
  }
  list(filter) {
    return this.api.doRequest('get', 'categoria-catalogo', filter);
  }
  get(id) {
    return this.api.doRequest('get', 'categoria-catalogo/' + id);
  }
  delete(id) {
    return this.api.doRequest('delete', 'categoria-catalogo/' + id);
  }
  update(obj) {
    return this.api.doRequest('put', 'categoria-catalogo', obj);
  }
  create(obj) {
    return this.api.doRequest('post', 'categoria-catalogo', obj);
  }
  getCampos(id){
    return this.api.doRequest('get', 'categoria-catalogo/campos/' + id);
  }
}
